.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 15px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    /*border-bottom: 1px solid #aaa;*/
    border-bottom: none;
    border-color: #aaa;
    /*bottom: -1px;*/
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    background: #DEDDDC;
    color: 	#303030;
  }

  .react-tabs__tab--selected:before {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--selected {
    border-bottom: none;
    outline: none;
    background: #fff;
    border-color: #aaa;
    color: black;
    bottom: -1px;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    border-bottom: none;
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
    border-bottom: none;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
    border-bottom: none;
  }
  