/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

table.vehicles {
  font-size: 12px;
}


table.sticky {
  border-collapse: separate;
}

table.sticky th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

/* Edge support for sticky table header borders */
@supports (-ms-ime-align:auto) {
  table.sticky thead th {
    outline: 1px solid #dbdbdb;                
    border:none; 
    outline-offset: -1px;
    padding:0;
  }
}

#chartContainer {
  height: 50vh;
}

.mobileContent #chartContainer {
  height: calc(100% - 51px);
}

span.react-dropdown-select-item:before {
  content: "\200D";
}  

#ScrollContainer:focus {
  outline: none;
}

.select:not(.is-multiple):not(.is-loading)::after {
  content: unset;
}

@media screen and (min-width: 1708px){
  .container {
     max-width: 1644px;
     width: 1644px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-size: 12px;
  font-weight: bold;
  visibility: hidden;
  width: 100px;
  background-color: #015B9F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  margin-left: -50px;
}

table.table tr {
  height: 1px;
}

table.table td {
  height: inherit;
}

@-moz-document url-prefix() {
  table.table td {
    height: 100%;
  }
}

button.islink, button.addNew {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #3273dc;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}
button.islink {
  height: 100%;
  width: 100%;
  display: block;
}
button.islink:focus {outline:0;}

.addNew, button.addNew {
  font-family: inherit;
  font-weight: bold;
}

.tableheader {
  background-color: #EBEEF7;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

thead {
  display: table-header-group;
}

thead button {
  flex-direction: row;
  transition-duration: 1s;
  width: 100%;
  text-align: left;
  border: 0;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  padding-right: 0px;
  margin: 0px;
  margin-right: 0px;
  background-color: #EBEEF7;
  cursor: pointer;
}

/*thead a:hover {background-color: #FFFFFF}*/

thead button.ascending::after {
  content: url(resources/caret-down.svg);
  display: inline-block;
  margin-left: 0.5em;
}

thead button.descending::after {
  content: url(resources/caret-up.svg);
  display: inline-block;
  margin-left: 0.5em;
}

.stickyTableHeader {
  max-width: 115em;
  max-height: 780px;
  overflow: scroll;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-height: 1080px) {
  .stickyTableHeader {
    max-height: 500px;
  }
}

@media screen and (min-width: 1024px) {
  .language-selection {
    padding-right: 150px;
  }
}

@media screen and (max-width: 1255px) {
  .stickyTableHeader {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1720px) {
  .stickyEntityTableHeadervehicles {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1708px) and (min-width: 1088px) {
  .loader-wrapper {
    left: 78% !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
/*
.stickyTableHeader::-webkit-scrollbar {
    display: none;
}
*/

/* Hide scrollbar for IE and Edge */
/*
.stickyTableHeader {
    -ms-overflow-style: none;
}
*/

table.installations {
  font-size: 12px;
  position: relative;
  border-collapse: collapse;
}

thead.installationsHeader th.installationsHeader {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: #EBEEF7;
  color: #000;
}

thead.installationsHeader th.installationsHeader:first-child {
  left: 0;
}

tbody.installationsBody th.installationsHeader {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #FFF;
  border-right: 1px solid #CCC;
}

entity-container {
  height: 70%;
}

.back-lightgray {
  background-color:	#F0F0F0;
}

@font-face {
  src: local('text-security-disc'), url(./fonts/text-security-disc.ttf) format('truetype');
  font-family: 'text-security-disc';
}

input.loginpwd {
  -webkit-text-security: disc;
  font-family: 'text-security-disc';
}

.desktop-login {
  padding-top: 9%;
  padding-bottom: 14%;
}

button.active {
  background: rgb(1, 91, 159);
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
  color: #fff !important;
}
.navbar-menu .navbar-item {
  padding-right: 45px;
}

.navbar.is-light .navbar-start>a.navbar-item:hover {
  background-color: #e8e8e8;
}

.navbar-menu.is-active .navbar-item {
  padding:20px;
}


.mobile-filters .react-dropdown-select-content span {
  color: rgb(51, 51, 51);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  width: calc(100% - 45px);
}

.mobile-filters .react-dropdown-select-clear{
  margin: 0 !important;
}

.mobile-filters .react-dropdown-select-input {
  width: calc(100% + 5px) !important;
  margin-left: 0;
}


.mobile-filters .react-dropdown-select-input:focus {
  z-index:31;
  background-color: #fff;
}

.mobileContent .columns {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 412px) {
  .mobileContent button {
    padding: 8px;
  }
}

button.active {
  background: rgb(1, 91, 159);
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
  color: #fff !important;
}

.mobile-content {
  margin: 10px;
  max-height: 100%;
}

.mobileContent #header {
  height: 36px;
  width: 100vw;
}

.mobileContent #footer {
  height: 32px;
  width: 100vw;
}

.mobileContent #content {
  /* For loader: */
  position: relative;
  /* content height is full - (header + footer) */
  height: calc(100vh - (88px + 32px));
  width: 100vw;
  overflow: auto;
  background-color: #fff;
  padding: 5px;
}

.react-dropdown-select.dropdownToLeft .react-dropdown-select-dropdown {
  top: 0;
  left: 100%;
}

.react-dropdown-select.dropdownToRight .react-dropdown-select-dropdown {
  top: 0;
  left: -100%;
}
